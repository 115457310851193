exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-browser-extension-uninstallation-form-tsx": () => import("./../../../src/pages/browser-extension/uninstallation-form.tsx" /* webpackChunkName: "component---src-pages-browser-extension-uninstallation-form-tsx" */),
  "component---src-pages-budget-insight-tos-tsx": () => import("./../../../src/pages/budget-insight/tos.tsx" /* webpackChunkName: "component---src-pages-budget-insight-tos-tsx" */),
  "component---src-pages-confirmation-tsx": () => import("./../../../src/pages/confirmation.tsx" /* webpackChunkName: "component---src-pages-confirmation-tsx" */),
  "component---src-pages-floa-confidentiality-policy-tsx": () => import("./../../../src/pages/floa-confidentiality-policy.tsx" /* webpackChunkName: "component---src-pages-floa-confidentiality-policy-tsx" */),
  "component---src-pages-floa-tos-tsx": () => import("./../../../src/pages/floa-tos.tsx" /* webpackChunkName: "component---src-pages-floa-tos-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-joko-tos-bnpl-tsx": () => import("./../../../src/pages/joko-tos-bnpl.tsx" /* webpackChunkName: "component---src-pages-joko-tos-bnpl-tsx" */),
  "component---src-pages-legals-tsx": () => import("./../../../src/pages/legals.tsx" /* webpackChunkName: "component---src-pages-legals-tsx" */),
  "component---src-pages-oauth-2-redirect-tsx": () => import("./../../../src/pages/oauth2-redirect.tsx" /* webpackChunkName: "component---src-pages-oauth-2-redirect-tsx" */),
  "component---src-pages-oauth-2-tsx": () => import("./../../../src/pages/oauth2.tsx" /* webpackChunkName: "component---src-pages-oauth-2-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-privacy-policy-us-tsx": () => import("./../../../src/pages/privacy-policy-us.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-us-tsx" */),
  "component---src-pages-shop-on-desktop-tsx": () => import("./../../../src/pages/shop-on-desktop.tsx" /* webpackChunkName: "component---src-pages-shop-on-desktop-tsx" */),
  "component---src-pages-tos-tsx": () => import("./../../../src/pages/tos.tsx" /* webpackChunkName: "component---src-pages-tos-tsx" */),
  "component---src-pages-tos-us-tsx": () => import("./../../../src/pages/tos-us.tsx" /* webpackChunkName: "component---src-pages-tos-us-tsx" */)
}

